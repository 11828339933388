import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const RequestQuoteSection = ({ className }) => {
  const initialState = {};

  const [state, setState] = React.useState(initialState);
  const formRef = React.useRef();
  const recaptchaRef = React.useRef();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  async function handleSubmit(e) {
    e.preventDefault();

    grecaptcha.ready(function() {
      grecaptcha
        .execute('6Lc0lyYhAAAAAE3xHoYx1RVpGjBg0cLbsVPYwdtQ', {
          action: 'submit',
        })
        .then(async function(token) {
          setIsSubmitting(true);
          try {
            await axios.post(
              'https://bckstg.app/api/wa/forms/6b3d091d-685f-48ee-89ce-7c9971f41660',
              {
                ...state,
                'g-recaptcha-response': token,
              }
            );
            alert('We received your message and will be in touch shortly!');
            formRef.current.reset();
            setState(initialState);
            setIsSubmitting(false);
          } catch (e) {
            alert(`We can't send your message. Please try again.`);
            setIsSubmitting(false);
          }
        });
    });
  }

  function handleInput(e) {
    const name = e.target.getAttribute('name');
    const value = e.target.value;
    state[name] = value;
    setState(state);
  }

  return (
    <section
      id="request-a-quote-section"
      className={`${className} py-5`}
      style={{ backgroundColor: `#F9F9F9` }}
    >
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js?render=6Lc0lyYhAAAAAE3xHoYx1RVpGjBg0cLbsVPYwdtQ"
          async
          defer
        ></script>
      </Helmet>
      <Container>
        <Row>
          <Col>
            <h3 className="font-weight-bolder text-primary text-center">
              Get Your Estimate
            </h3>
          </Col>
        </Row>
        <Form onSubmit={e => handleSubmit(e)} ref={formRef}>
          <input type="hidden" name="form_id" value="3" />
          <Row className="mb-4">
            <Col xs="12" md="6">
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  placeholder="First and Last Name"
                  name="name"
                  required={true}
                  onChange={e => handleInput(e)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  placeholder="johndoe@example.com"
                  name="email"
                  type="email"
                  required={true}
                  onChange={e => handleInput(e)}
                />
              </Form.Group>
              <Form.Group className="mb-md-0">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  placeholder="555-555-5555"
                  name="phone"
                  required={true}
                  onChange={e => handleInput(e)}
                />
              </Form.Group>
            </Col>
            <Col xs="12" md="6">
              <Form.Group className="h-100 d-flex flex-column">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  className="flex-grow-1"
                  placeholder="Message..."
                  required={true}
                  onChange={e => handleInput(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center text-center">
              <div className="mb-4">
                <div ref={recaptchaRef} id="recaptcha"></div>
              </div>
              {/* <Recaptcha
                sitekey=""
                render="explicit"
                verifyCallback={recaptchaCallback}
              /> */}
              <Button
                type="submit"
                className="text-uppercase font-weight-bold"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </section>
  );
};

RequestQuoteSection.propTypes = {
  className: PropTypes.string,
};

export default RequestQuoteSection;
