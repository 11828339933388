import React from 'react';
import PropTypes from 'prop-types';

const RenderHtml = ({ html, className, tag = 'div' }) => {
  const Tag = tag;
  return (
    <Tag
      className={className}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
};

RenderHtml.propTypes = {
  html: PropTypes.any,
  className: PropTypes.string,
  tag: PropTypes.string,
};

export default RenderHtml;
