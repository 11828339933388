/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';

import Header from './header';
import RecentProjects from '../components/RecentProjects';
import RequestQuoteSection from '../components/RequestQuoteSection';
import TestimonialSlider from '../components/TestimonialSlider';

import '../styles/layout.scss';

const Layout = ({ children, hasRecentWork, hasTestimonials }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#026f3a" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <link rel="stylesheet" href="https://use.typekit.net/ybn0wmg.css" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <Container>
        {hasRecentWork ? <RecentProjects /> : null}
        {hasTestimonials ? <TestimonialSlider /> : null}
      </Container>
      <RequestQuoteSection />
      <div className="text-center py-2" style={{ backgroundColor: `#F9F9F9` }}>
        <a
          href="https://www.bbb.org/us/il/river-grove/profile/mason-contractors/mad-masonry-0654-90028002/#sealclick"
          target="_blank"
          rel="nofollow"
        >
          <img
            src="https://seal-chicago.bbb.org/seals/blue-seal-293-61-bbb-90028002.png"
            style={{ border: 0 }}
            alt="Mad Masonry BBB Business Review"
          />
        </a>
      </div>
      <footer className="bg-primary text-white text-center p-4">
        <p>
          © {new Date().getFullYear()} Mad Masonry. All Rights Reserved. 2655
          Davisson St. • River Grove, IL 60171 • (708) 716-3495
        </p>
        Website by
        {` `}
        <a href="https://antlur.co" className="text-white">
          Antlur, LLC
        </a>
      </footer>
      {/* <a href="https://www.bbb.org/us/il/river-grove/profile/mason-contractors/mad-masonry-0654-90028002/#sealclick" target="_blank" rel="nofollow"><img src="https://seal-chicago.bbb.org/seals/blue-seal-293-61-bbb-90028002.png" style="border: 0;" alt="Mad Masonry BBB Business Review" /></a>

<iframe border="0" frameborder="0" style="border: 0; height:61px; width:293px;" src="https://seal-chicago.bbb.org/frame/blue-seal-293-61-bbb-90028002.png?chk=756CD18ED4"></iframe> */}
    </>
  );
};

Layout.defaultProps = {
  hasRecentWork: true,
  hasTestimonials: true,
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hasRecentWork: PropTypes.bool,
  hasTestimonials: PropTypes.bool,
};

export default Layout;
