import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Carousel } from 'react-bootstrap';
import Testimonial from './Testimonial';

const TestimonialSlider = () => {
  const data = useStaticQuery(graphql`
    {
      testimonials: allWordpressWpTestimonials {
        edges {
          node {
            id
            acf {
              company
              name
              rating
            }
            title
            content
          }
        }
      }
    }
  `);

  return (
    <section className="py-5">
      <div className="container">
        <h2 className="text-center font-weight-bolder text-primary mb-4">
          Testimonials
        </h2>
        <Carousel>
          {data.testimonials.edges.map((edge, index) => {
            return (
              <Carousel.Item className="Item" key={index}>
                <Testimonial
                  title={edge.node.title}
                  content={edge.node.content}
                />
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default TestimonialSlider;
