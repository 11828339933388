import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../styles/featured-services.scss';

const FeaturedServices = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      thumbnails: wordpressPage(slug: { eq: "gallery" }) {
        id
        blocks {
          attrs {
            media {
              url {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 250, maxHeight: 250) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = [
    data.thumbnails.blocks[0].attrs.media[0],
    data.thumbnails.blocks[0].attrs.media[1],
    data.thumbnails.blocks[0].attrs.media[2],
    data.thumbnails.blocks[0].attrs.media[3],
  ];

  return (
    <section className={`featured-services-section ${className}`}>
      <Container>
        <h2 className="text-center font-weight-bolder text-primary mb-4">
          Our Recent Work
        </h2>
        <Row>
          {images.map((image, index) => {
            return (
              <Col xs={6} sm={6} lg={3} className="mb-4" key={index}>
                <Link to={`/gallery?index${index}`}>
                  <Img fluid={image.url.localFile.childImageSharp.fluid} />
                </Link>
              </Col>
            );
          })}
        </Row>
        <Row className="py-4">
          <Col>
            <Link to="/gallery" style={{ display: `inline-block` }}>
              <Button
                variant="primary"
                className="font-weight-bolder text-uppercase"
              >
                See More Work
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

FeaturedServices.propTypes = {
  className: PropTypes.string,
};

export default FeaturedServices;
