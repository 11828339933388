import React from 'react';
import PropTypes from 'prop-types';
import RenderHtml from './RenderHtml';

const Testimonial = props => {
  return (
    <div className="jumbotron jumbotron-fluid text-center px-3 px-lg-5">
      <RenderHtml html={props.content} />
      <p>
        <strong>- {props.title}</strong>
      </p>
    </div>
  );
};

Testimonial.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
};

export default Testimonial;
