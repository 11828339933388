import React from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { Container, Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { AllHtmlEntities } from 'html-entities';

const entities = new AllHtmlEntities();

function InternalLink({ title, url }) {
  return (
    <Link to={url} className="nav-link letter-spacing-1">
      {title}
    </Link>
  );
}

InternalLink.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

const Header = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      wordpressMenusMenusItems {
        id
        name
        items {
          wordpress_id
          post_author
          post_date
          post_date_gmt
          post_content
          post_title
          post_excerpt
          post_status
          comment_status
          ping_status
          post_password
          post_name
          to_ping
          pinged
          post_modified
          post_modified_gmt
          post_content_filtered
          post_parent
          guid
          menu_order
          post_type
          post_mime_type
          comment_count
          filter
          db_id
          menu_item_parent
          object_id
          object
          type
          type_label
          url
          title
          target
          attr_title
          description
          xfn
          child_items {
            wordpress_id
            post_author
            post_date
            post_date_gmt
            post_content
            post_title
            post_excerpt
            post_status
            comment_status
            ping_status
            post_password
            post_name
            to_ping
            pinged
            post_modified
            post_modified_gmt
            post_content_filtered
            post_parent
            guid
            menu_order
            post_type
            post_mime_type
            comment_count
            filter
            db_id
            menu_item_parent
            object_id
            object
            type
            type_label
            url
            title
            target
            attr_title
            description
            xfn
          }
        }
      }
    }
  `);

  const { items: menuItems } = data.wordpressMenusMenusItems;
  return (
    <header>
      <Container>
        <Navbar bg="white" variant="light" expand="lg">
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/img/Mad-Masonry-Logo.svg"
                className="headerLogo"
                alt="Mad Masonry Logo"
              />
            </Link>
          </Navbar.Brand>
          <div className="d-flex justify-content-end align-items-center">
            <a href="tel:+1(708) 716-3495" className="d-md-none">
              <FontAwesomeIcon icon={faPhoneAlt} />
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="ml-3" />
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="flex-column align-items-end"
          >
            <div className="d-none d-lg-block">
              <a
                href="tel:+1(708) 716-3495"
                className="font-weight-bolder mr-4 align-middle letter-spacing-1"
              >
                (708) 716-3495
              </a>
              <Button href="#request-a-quote-section" className="">
                Get Estimate
              </Button>
            </div>
            <Nav>
              {menuItems.map((menuItem, pIndex) => {
                const to = menuItem.url.replace('https://masonfix.com', '');
                const title = entities.decode(menuItem.title);
                const hasDropdown =
                  menuItem.child_items && menuItem.child_items.length;
                // If has Dropdown
                if (hasDropdown) {
                  return (
                    <NavDropdown title={menuItem.title} key={pIndex}>
                      {/* Render the children */}
                      {menuItem.child_items.map((childItem, index) => {
                        // Make url relative
                        const childTo = childItem.url.replace(
                          'https://masonfix.com',
                          ''
                        );

                        // Decode special charcters
                        const childTitle = entities.decode(childItem.title);

                        return (
                          <NavDropdown.Item key={index} as="div">
                            <InternalLink title={childTitle} url={childTo} />
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  );
                }
                // If No dropdown
                return <InternalLink title={title} url={to} key={to} />;
              })}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <Button
          href="#request-a-quote-section"
          className="w-100 mt-2 mb-4 d-md-none"
        >
          Get Estimate
        </Button>
      </Container>
      <div className="d-sm-block"></div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
